import OrderService from "../api/order-service";
import { goEcPayOrder} from "../utils/ec"

let seconds = 0;
const cancelOrder = state => OrderService.cancelOrder(state.orderId)
const paymentOrder = state => OrderService.payingOrder(state.orderId)
            .then(orderId => goEcPayOrder(orderId))

function onMounted(state, orderId) {
    return OrderService.getOrder(orderId).then(order => {
        state.order = order
        createRefreshExpiredTime(state)
    });
}

function onCreated() {
    // initEcScript(User.getClient());
}

const isExpiredRemind = (state)=> state.order.status == "PAYING" || state.order.status == "UNPAID";
const refreshExpiredTime = (state) => {
    seconds = seconds + 1;
    state.expiredTime = getExpiredTime(state.order.expiredSeconds - seconds);
    createRefreshExpiredTime(state);
}
const createRefreshExpiredTime = (state) => {
    if( isExpiredRemind(state)) {
        if(!state.expiredTime) {
            state.expiredTime = getExpiredTime(state.order.expiredSeconds - seconds);
        }
        setTimeout(()=> refreshExpiredTime(state), 1000)
    }
}
function getExpiredTime(expiredSeconds) {
    let seconds = expiredSeconds;
    let minutes = parseInt(seconds / 60)
    let second = seconds % 60;
    if(second == 0) {
        return minutes + "分";
    } else if( minutes == 0) {
        return second + "秒";
    }
    return minutes + "分" + second + "秒";
}

export default {
    cancelOrder,
    paymentOrder,
    isExpiredRemind,
    onMounted,
    onCreated
}